<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          :color="color.theme"       
          >
          <v-toolbar-title>{{ $t("consentlog") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            dark
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        
        <v-divider></v-divider>
                <v-card class="ma-10">
                    <div>
                      <v-data-table
                        :headers="$t('default') === 'en' ? headerEN : headerTH"
                        :items="dataprocesslog"
                        class="elevation-0"
                        :no-data-text="$t('tablefile.empty')"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                        :page.sync="page"
                      >
                        <!-- <template v-slot:[`header.type`]="{ header }">
                          <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                        </template> -->
                        <template v-if="$t('default') === 'th'" v-slot:[`header.name_th`]="{ header }">
                          <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-else v-slot:[`header.name_en`]="{ header }">
                          <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:[`header.system_business_name`]="{ header }">
                          <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:[`header.process_dtm`]="{ header }">
                          <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <template v-slot:[`header.process_name`]="{ header }">
                          <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr>
                            <td width="25%" v-if="$t('default') === 'th'">{{ props.item.name_th }}</td>
                            <td width="25%" v-else>{{ props.item.name_en }}</td>
                            <td class="text-center" width="25%">{{ props.item.system_business_name }}</td>
                            <td class="text-center" width="25%">{{ formatdatetime(props.item.process_dtm) }}</td>
                            <td class="text-center" width="25%">{{ props.item.process_name }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

  export default {
    props: ["show"],
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        dataprocesslog:[],
        page: 1,
        headerTH: [
        // {
        //   text: "#",
        //   align: "center",
        //   value: "type",
        //   width: "7%",
        //   sortable: false,
        // },
        {
          text: "admin.name",
          align: "",
          value: "name_th",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.system",
          align: "center",
          value: "system_business_name",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.edit_date",
          align: "center",
          value: "process_dtm",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.consent",
          align: "center",
          value: "process_name",
          width: "25%",
          sortable: false,
        },
      ],
      headerEN: [
        // {
        //   text: "#",
        //   align: "center",
        //   value: "type",
        //   width: "7%",
        //   sortable: false,
        // },
        {
          text: "admin.name",
          align: "",
          value: "name_en",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.system",
          align: "center",
          value: "system_business_name",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.edit_date",
          align: "center",
          value: "process_dtm",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.consent",
          align: "center",
          value: "process_name",
          width: "25%",
          sortable: false,
        },
      ],
      }
    },
    computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.countdata;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      console.log("paginatedData()");
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.dataprocesslog.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    },
    watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.get_log_consent();
          }
        },
      },
    },
    methods: {
      formatdatetime(_datetime) {
      // console.log(this.rootfile);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    async get_log_consent(){
      this.log_data=[];
      this.dataprocesslog=[];
        console.log("get_log_consent");
        let payload ={
           business_id: this.dataAccountActive.business_info.business_id,
        };
        console.log("payload get_log_consent", payload);
        let auth = await gbfGenerate.generateToken();
        console.log(auth);
        console.log("auth",auth.code);
        this.axios
            .post(
              process.env.VUE_APP_SERVICE_AUTHORIZE_API +
                "/api/get_log_consent",
              payload,
              { headers: { Authorization: auth.code } }
            )
            .then((response) => {
              console.log("responsedก่อนstatusok", response);
              if (response.data.status === "OK") {
                console.log("response ok");
                this.log_data= response.data.data;
                console.log("this.personal_log_data",this.log_data);
                var i;
                for(i=0;i<this.log_data.length;i++){
                let data_log = {};
                data_log ["name_th"] = this.log_data[i]["name_th"]
                data_log ["name_en"] = this.log_data[i]["name_en"]
                data_log ["process_dtm"] = this.log_data[i]["process_dtm"]
                data_log ["process_name"] = this.log_data[i]["process_name"]
                data_log ["system_business_name"] = this.log_data[i]["system_business_name"]
                data_log ["system_id"] = this.log_data[i]["system_id"]

                this.dataprocesslog.push(data_log);
                console.log("dataprocesslog",this.dataprocesslog);
                }
              }
            })
      }
    },
    // mounted(){
    //   this.get_log_consent();
    // },
  }
</script>

<style>

</style>