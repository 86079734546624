var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","scrim":false,"transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":_vm.color.theme}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("consentlog")))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider'),_c('v-card',{staticClass:"ma-10"},[_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.$t('default') === 'en' ? _vm.headerEN : _vm.headerTH,"items":_vm.dataprocesslog,"no-data-text":_vm.$t('tablefile.empty'),"hide-default-footer":true,"page":_vm.page},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(_vm.$t('default') === 'th')?{key:"header.name_th",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"pointer",style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}}:{key:"header.name_en",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"pointer",style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"header.system_business_name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"pointer",style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"header.process_dtm",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"pointer",style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"header.process_name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"pointer",style:(_vm.headerTable)},[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"item",fn:function(props){return [_c('tr',[(_vm.$t('default') === 'th')?_c('td',{attrs:{"width":"25%"}},[_vm._v(_vm._s(props.item.name_th))]):_c('td',{attrs:{"width":"25%"}},[_vm._v(_vm._s(props.item.name_en))]),_c('td',{staticClass:"text-center",attrs:{"width":"25%"}},[_vm._v(_vm._s(props.item.system_business_name))]),_c('td',{staticClass:"text-center",attrs:{"width":"25%"}},[_vm._v(_vm._s(_vm.formatdatetime(props.item.process_dtm)))]),_c('td',{staticClass:"text-center",attrs:{"width":"25%"}},[_vm._v(_vm._s(props.item.process_name))])])]}}],null,true)})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }